.gray-bg, .bg-muted {
  background-color: #f3f3f4;
  padding-bottom: 20px;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
html, body {
  min-height: 250px;
}
body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f3f3f4;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}
.loginscreen.middle-box {
  width: 300px;
}
.middle-box {
  z-index: 100;
  margin: 0 auto;
  height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.logo-name {
  color: #e6e6e6;
  font-size: 180px;
  font-weight: 800;
  margin-bottom: 0;
}
.middle-box h1 {
  font-size: 55px;
}
h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}
h3 {
  font-size: 16px;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
.form-control, .single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.form-control, .form-control:focus, .has-error .form-control:focus, .has-success .form-control:focus, .has-warning .form-control:focus, .navbar-collapse, .navbar-form, .navbar-form-custom .form-control:focus, .navbar-form-custom .form-control:hover, .open .btn.dropdown-toggle, .panel, .popover, .progress, .progress-bar {
  box-shadow: none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
  background-color: #18a689 !important;
  border-color: #18a689 !important;
  color: #FFFFFF !important;
  box-shadow: none;
}
.btn.active, .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125)!important;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125)!important;
}
.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
}
.full-width {
  width: 100% !important;
}
.form-control:focus, .single-line:focus {
  border-color: #1ab394;
}
.form_login{
  position: relative;
  padding-top: 30px;
}
.form_login .form-group{
  padding-bottom: 0;
}
.error-mess{
  color: #ed5565
}
.form_login .error-mess{
  position: absolute;
  top: 0;
}
header{
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  background: #fff;
  z-index: 55;
  left: 0;
  top:0;
  transition: height .3s;
}
header.sticky{
  height: 86px;
}
header .container1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  padding: 0 15px;
}
header .left{
  display: flex;
  align-items: center;
}

header .left nav ul li a{
  font-size: 16px;
  color: #222222;
  position: relative;
  padding-bottom: 12px;
}
header .left nav ul li.active a:before{
  content: '';
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-135deg, #ccc 0%, #fff 100%);
}
header .left nav ul li a:before{
  content: '';
  height: 4px;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-135deg, #ccc 0%, #fff 100%);
  transition: all .2s;
}
header .left nav ul li a.active:before{
  width: 100%;
}
header .left nav ul li a:hover:before{
  width: 100%
}
header .left{
  position: fixed;
  display: flex;
  left: 0;
  align-items: flex-start;
  top: 0;
  z-index:1;
  height: 100%;
  transform: translateX(0);
  transition: all 0.3s;
}
.active_menu header .left{
  transform:  translateX(-100%) translateX(55px);
}
header .left nav ul{
  flex-direction: column;
  justify-content: center;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
header .left .nav_cont{
  flex-direction: column;
  padding: 15px;
  background: #263a4a;
  height: 100%;
  overflow: auto;
}
header .left nav ul li{
  padding: 0 15px;
  padding-bottom: 20px;
}
.nav_button{
  display: block;
  padding: 12px;
  width: 57px;
  height: 59px;
  box-sizing: border-box;
  margin-top: 6px;
  padding-top: 0;
  cursor: pointer;
}


.nav_button .icon-bar {
  width: 30px;
  height: 5px;
  background: #443d3c;
  border-radius: 5px;
  margin: 5px 0;
}


.nav_button .bar1,.nav_button .bar3 {
  transition: all 0.3s;
}
.nav_button .bar1 {
  transition: all 0.3s;
  transform: rotate(45deg) translateX(7px);
}
.nav_button{
  margin-top: 12px;
}
.nav_button .bar2 {
  display: none;
}
.nav_button .bar3 {
  transition: all 0.3s;
  transform: rotate(-45deg) translateX(7px);
}
.active_menu .nav_button .icon-bar{
  transform: rotate(0) translateX(0);
}
.active_menu .nav_button .bar2 {
  display: block;
}
.active_menu .nav_button{
  margin-top: 6px;
}

.row{
  margin: 0;
}
header .left nav ul li a{
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
}
header .left nav ul li a i{
  padding-right: 10px;
}
header .left nav ul li .drop_menu ul a{
  color: #000;
}


a, a:hover, a:focus {
  text-decoration: none;
}
.loading{
  height: 4px;
  background: #15545d;
  z-index: 99999999;
  position: fixed;
  top:0;
  left: 0;
}
.logout{
  cursor: pointer;
  color: #999c9e;
  font-size:14px;
  font-weight: 600;
}
.logout i {
  margin-right: 6px;
}
.logo{
  width: 50px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.main_container{
  margin: 70px 0 0 184px;
  transition: all .3s;
  padding: 15px 25px;
}
.active_menu .main_container{
  margin: 70px 0 0 0;
}
.search input{
  width: 250px;
  padding-right: 36px;
}
.search{
  position: relative;
}
.search:after{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f002";
  font-size:14px;
  position: absolute;
  right: 15px;
  top: 13px;
  color: #cbcbce;
}
table tr th.clickable{
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
table tr th.clickable:after{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0dc";
  font-size:14px;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #cbcbce;
}
table tr th.clickable.active_up:after{
  content: "\f0de";
  color: #676a6c;
}
table tr th.clickable.active_down:after{
  content: "\f0dd";
  color: #676a6c;
}
.chart_container{
  height:300px;
  padding: 0 15px;
  background: #fff;
  width: 100%;
  position: relative;
}

.cont1{
  width: calc(33.333% - 30px);
  background: #fff;
  margin: 15px;
  height:100%;
}
.cont2{
  width: calc(50% - 30px);
  background: #fff;
  margin: 15px;
}
.chart_container1{
  width: 100%;
  padding-top: 60%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.recharts-responsive-container{
  position: absolute;
  left:0;
  top:0;
}
.chart_head {
  background-color: #ffffff;
  border-top: 2px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
  display: flex;
  justify-content:space-between;
}
.chart_head input{
  width: 190px;
  font-size: 14px;
  height: 30px;
}
.head_txt{
  font-size:18px;
  font-weight: 600;
}
.modal-content{
  position: relative;
  padding-top: 55px;
}
button.close_mod {
  border: none;
  outline: none;
  background: #fff;
  font-size: 23px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.modal_head {
  font-size: 24px;
  margin-bottom: 20px;
  position: absolute;
  left: 10px;
  top: -45px
}
.modal-body {
  border-top: 1px solid #ccc;
}
span.label_txt {
  padding-bottom: 2px;
  display: block;
  font-weight: 600;
}
.m_form label{
  position: relative;
  padding-bottom: 20px;
}
.m_form label.error_lab input, .m_form label.error_lab textarea{
  border: 1px solid #ed5565;
}
.m_form label textarea{
  resize: none;
  height:130px;
}
span.error_mes {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 11px;
  color: #ed5565;
}
.m_form label input {
  height: 30px;
}
.but_cont{
  display: flex;
  justify-content:space-between;
}
.but_cont button{
  width: calc(50% - 10px);
}

.actions .edit,.actions .delete{
  cursor: pointer;
  font-size:22px;
}
.actions .edit {
  color: green;
}
.actions .delete {
  color: red;
}
.actions .edit:before {
  content: "\f044";
}
.actions .delete:before {
  content: "\f1f8";
}
.actions .delete{
  padding-left: 10px;
}
.widget{
  padding: 25px;
  color: #ffffff;
}
.color1{
  background-color: #23c6c8;
}
.color3{
  background-color: #1ab394;
}
.color2{
  background-color: #4b72b7;
}
.txt_widget{
  font-size:20px;
}
.txt_lab{
  margin-left:10px;
}
.pict img{
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.remove_pic{
  margin-left:15px;
  cursor: pointer;
}
.remove_pic:before{
  content: "\F1F8";
  font-size:22px;
  margin-right: 5px;
}
.img_edit{
  display: flex;
  align-items: center;
  margin-bottom:10px;
}
.img_edit img{
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.main_container table td{
  vertical-align: middle;
}
.pagin_cont{
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagin_cont .ic_left:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url('../img/active_right.svg') no-repeat center;
  transform: rotate(-180deg);
}
.pagin_cont .ic_right:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url('../img/active_right.svg') no-repeat center;
}
.pagination li a{
  border: none;
  width: 42px;
  height: 42px;
  margin: 0 7px;
  border-radius: 50%;
  cursor: pointer;
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #7C86A2;
  text-align: center;
  line-height: 25px;
}
.pagination li:first-child a,.pagination li:nth-child(2) a,.pagination li:last-child a, .pagination li:nth-last-child(2) a  {
  width: 35px;
  padding: 0.5rem 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.pagination li:first-child a:hover,.pagination li:nth-child(2) a:hover,.pagination li:last-child a:hover, .pagination li:nth-last-child(2) a:hover{
  background: transparent;
  color: #7C86A2;
}
.pagination li.active a {
  background: #263a4a !important;
  border:none;
  box-shadow:none;
}
.f_link2:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url(../img/active_right.svg) no-repeat center;
  transform: rotate(-180deg);
}
.b_link2:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url(../img/active_right.svg) no-repeat center;
  transform: rotate(0);
}
.pagination li.disabled a {
  opacity: 0.4;
}
.pagination li a {
  position: relative;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.pagination li a:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.pagination li.active a{
  color: #fff;
}
.pagination .li.disabled a:hover{
  background: transparent !important;
  border:none;
  box-shadow:none;
}
.pagination li a.disabled {
  opacity: 0.4;
}
select{
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 0 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  height:30px;
  font-size: 1rem;
  line-height: 1.5;
}
select:focus{
  border: 1px solid #1ab394;
  box-shadow: none;
  outline: none;
}
table tr.edited {
  outline: 1px solid red;
}
.table td{
  border-top: none;
}
span.fa-eye.fa {
  font-size: 22px;
  color: #263a4a;
  margin-left: 7px;
  cursor: pointer;
}
.list_data{
  margin: 0;
  padding: 0;
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  -webkit-column-gap: 35px; /* Chrome, Safari, Opera */
  -moz-column-gap: 35px; /* Firefox */
  column-gap: 35px;
  -webkit-column-rule-style: solid; /* Chrome, Safari, Opera */
  -moz-column-rule-style: solid; /* Firefox */
  column-rule-style: solid;
  column-rule-width: 2px;
  list-style: none;
}
.list_data li{
  margin-bottom:5px;
}
.dont_list{
  font-size:18px;
}
.table_head_txt {
  display: none;
  font-weight: bold;
  padding-right: 10px;
}
.selectBox{
  margin-bottom:15px;
  width: 100%;
  height:38px;
  display: none;
}
span.fa-envelope.fa {
  font-size: 20px;
  padding-left: 5px;
  color: #5e7792;
  cursor: pointer;
}
.head_mess {
  text-align: center;
  font-size: 34px;
  margin-bottom: 15px;
}
span.fa.fa-check {
  font-size: 70px;
  color: #3fdfae;
  text-align: center;
}
span.fa.fa-warning {
  font-size: 70px;
  color: red;
  text-align: center;
}
.m_lbl{
  margin-right:25px;
  margin-top: 20px;
}
.m_form label.m_lbl input{
  width: 240px;
  height:37px;
  color: #000;
  padding-right: 25px;
}
.m_form label.m_lbl:after{
  content: '$';
  display: block;
  position: absolute;
  right: 10px;
  top: 4px;
  color: #827878;
}
.width_95{
  width: 95px;
}
.logo1{
  width: 150px;
}